@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *{
        font-family: 'Poppins';
        font-weight: 500;
    }

    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 1.5rem;
    }
    h3{
        font-size: 1.25rem;
    }
    #hamburger{
        position: relative;
        width:22px;
        height: 18px;
        margin: auto 1rem;
        
    }
    #hamburger div{
        position: absolute;
        height: 3px;
        width:100%;
        background-color: rgba(54, 158, 76, 1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    #hamburger>div:nth-child(2){
        margin: auto;
        top:0;
        bottom:0;
    }
    #hamburger>div:nth-child(3){
        bottom: 0;
    }
    .collapse div, 
    .collapse svg, 
    .zoom-animation
    /* .zoom-animation-sm */
    {
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .menu>p:hover{
        border-bottom-width: 4px;
        color: rgba(54, 158, 76, 1);
    }
    :root {
        --swiper-theme-color: rgba(30, 165, 63, 1) !important;
    }
    .zoom-animation:hover{
        transform: scale(1.5);
    }
}

.swiper-button-prev {
    background-color: rgba(220, 220, 220, 0.3);
    margin-left: 10%;
    padding: 2.5rem;
    border-radius: 50%;
}

.swiper-button-prev:hover {
    background-color: rgba(220, 220, 220, 0.5);
}

.swiper-button-next {
    background-color: rgba(220, 220, 220, 0.3);
    margin-right: 10%;
    padding: 2.5rem;
    border-radius: 50%;
}

.swiper-button-next:hover {
    background-color: rgba(220, 220, 220, 0.5);
}

.swal2-confirm {
    background-color: #34b575!important;
}

@layer components{
    @media (max-width: 480px){
        /* .zoom-animation-sm:hover{
            transform: scale(1.25);
            font-size: 0.7rem;
        }

        div:has(>.zoom-animation-sm):hover{
            width: 100% !important;
            padding: 1rem auto;
        } */
        
        .zoom-animation:hover{
            transform: scale(1.75);
        }
    }
    @media (max-width: 640px) { 
        .hide{
            opacity: 0 !important;
        }
    }
    @media (max-width: 767px){
        #hamburger.active>div:first-child{
            -webkit-transform: rotate(135deg) translate3d(5px,-5px,0);
            transform: rotate(135deg) translate3d(5px,-5px,0);
        }
        #hamburger.active>div:nth-child(2){
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
        }
        #hamburger.active>div:nth-child(3){
            -webkit-transform: rotate(-135deg) translate3d(5.8px,5.8px,0);
            transform: rotate(-135deg) translate3d(5.8px,5.8px,0);
        }
        #collapse, #collapse *{
            transform: translate(0,-5rem);
            height:0;
            -webkit-transition: all .5s;
            transition: all .5s;
        }
        #collapse.show, #collapse.show *{
            transform: translate(0,0);
            height:auto;
            -webkit-transition: all .5s;
            transition: all .5s;
        }
        #collapse.show{
            display:block;
            padding: 1rem 2rem .5rem;
        }
        #collapse.show>a{
            display:block;
            margin-bottom: 1rem;
        }

        #collapse.show>a{
            display:block;
            margin-bottom: 1rem;
        }
        #collapse.show>a:last-child>img{
            height: 100px;
        }
        .collapse>div:first-child{
            border-bottom-width: 1px;
        }
        .show-faq>div:first-child>p{
            font-weight: bold;
            text-decoration-line: none;
        }
        .show-faq>div:last-child{
            padding: 1rem 2rem;
            height:10rem;
            border-bottom-width: 1px; 
            overflow-y: auto;
        }
        .show-faq svg{
            transform: rotate(180deg);
        }
    }
}

@media (max-width: 1000px) {
    .swiper-button-prev {
        background-color: rgba(220, 220, 220, 0.3);
        margin-left: 0%;
        padding: 2.5rem;
        border-radius: 50%;
    }

    .swiper-button-next {
        background-color: rgba(220, 220, 220, 0.3);
        margin-right: 0%;
        padding: 2.5rem;
        border-radius: 50%;
    }
}